import PropTypes from "prop-types";
import { useContext } from "react";

import {
  useBasiqConsent,
  useExternalFinanceAdapter,
  useYodleeConnect,
} from "../hooks/account.hooks";
import {
  DEFAULT_MONTHLY_RENT,
  DEFAULT_SIGNUP_BUYING_GOAL,
  EXTERNAL_FINANCE_ADAPTER,
  scenarioTypes,
} from "../commons/enum";
import DataLoadingContext from "../context/data-loading-context";
import {
  useScenarioList,
  useUpdateUserEmployment,
} from "../hooks/scenario.hooks";
import { useMyInfo } from "../hooks/user.hooks.ts";
import { primaryColor } from "../scss/colors.scss";
import Plus from "../svgs/Plus";
import ExternalAccountsConnectUiDialog from "./ExternalAccountsConnectUIDialog/external-accounts-connect-ui-dialog";

const AddAccountButton = ({ title = "Add Accounts", onClick }) => {
  const {
    connect: connectYodleeAccounts,
    uiShown: externalAccountsConnectUIShown,
    connectSuccessfullyRef,
    targetElementIdRef: externalAccountsConnectUIIdRef,
  } = useYodleeConnect();
  const { data: adapterType } = useExternalFinanceAdapter();
  const { open: openBasiqConnect } = useBasiqConsent();

  const { setAppState } = useContext(DataLoadingContext);
  const { data: userInfo } = useMyInfo();
  const { firstName } = userInfo || {};
  const { data: scenarioList = [] } = useScenarioList();
  const updateUserEmployment = useUpdateUserEmployment();
  const handleOnSuccess = () => {
    if (scenarioList.length <= 0) {
      setAppState({
        pendingToCreateScenarios: [
          {
            buyingGoal: DEFAULT_SIGNUP_BUYING_GOAL,
            firstName,
          },
          {
            scenarioType: scenarioTypes.BUY_AN_INVESTMENT,
            buyingGoal: DEFAULT_SIGNUP_BUYING_GOAL,
            monthlyRent: DEFAULT_MONTHLY_RENT,
            firstName,
          },
        ],
      });
    }
    updateUserEmployment.mutate();
    connectSuccessfullyRef.current = true;
  };

  const defaultOnClick = () => {
    if (adapterType === EXTERNAL_FINANCE_ADAPTER.BasicQ) {
      openBasiqConnect();
    } else if (adapterType === EXTERNAL_FINANCE_ADAPTER.Yodlee) {
      connectYodleeAccounts({
        onSuccess: handleOnSuccess,
      });
    }
  };

  return (
    <>
      {adapterType === EXTERNAL_FINANCE_ADAPTER.Yodlee && (
        <ExternalAccountsConnectUiDialog
          id={externalAccountsConnectUIIdRef.current}
          open={externalAccountsConnectUIShown}
        />
      )}
      <div
        className="la-container add-account"
        onClick={onClick || defaultOnClick}
      >
        <div>{title}</div>
        <div className="plus-icon">
          <Plus fill={primaryColor} />
        </div>
      </div>
    </>
  );
};

export default AddAccountButton;

AddAccountButton.propTypes = {
  title: PropTypes.string,
};
